@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'Lato', sans-serif;
}

.fw-bold {
  font-weight: bold;
}
.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

html, body, #root {
  min-height: 100vh;
  max-width: 100vw;

  width: 100%;
}

body {
  color: #fff !important;
  background: #06777A !important;
}

.App > section {
  min-height: 30vh;
}

ul, li {
  list-style-type: none;
}

.text-depoimento {
  background: #065170;
  color: #E5E5E5;
}

.desc-depoimento {
  width: 70%;
  font-size: 28px;
  line-height: 34px;

  color: #E5E5E5;
}

.button-depoimento {
  border: none;
  border-radius: 59px;
  background: #146181;

  width: 232px;
  height: 68px;
  font-size: 16px;
  
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;

  transition: all .2s ease-in-out;
}

.button-depoimento:hover {
  transform: scale(1.05)
}

.button-contato {
  width: 232px;
  height: 68px;

  border: none;
  background: #FF4A6B !important;
  border-radius: 59px;

  font-size: 16px;
  
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  
  transition: all .2s ease-in-out !important;
}

.button-contato:hover {
  transform: scale(1.05);
  color: #fff;
  text-decoration: none;
}

.card-problemas {
  margin: 1.5rem;
  text-align: left;
}

.card-problemas h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  color: #fff;
  margin-bottom: 1rem;
}

.card-problemas p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;

  color: #E5E5E5;
}

.button-agenda {
  border: none !important;
  width: 348px;
  height: 68px;

  background: #FF4A6B;
  background-color: #FF4A6B !important;
  border-radius: 59px !important;
  color: #FFFFFF !important;

  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;

  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out !important;
}

.button-agenda:hover {
  transform: scale(1.05);
}

.conteiner-card {
  width: 100%;

  overflow-x: auto;
  justify-content: start;
  padding-bottom: 1rem;
}

.conteiner-card::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #167A7C;
}
.conteiner-card::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  border-radius: 10px;
  background-color: #167A7C;
}
.conteiner-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #40ACAE;
}

.conteiner-card .card-body .elipse-img-card {
  width: 67px;
  height: 67px;
  position: absolute;
  
  background: #E2F9FA;
  border-radius: 50%;
  z-index: 10;

  margin-left: 0.3rem;
}
.conteiner-card .card-body .card-ico {
  position: relative;
  z-index: 12;
}

.conteiner-card .card {
  height: 339px;
  width: 313px;
  min-width: 285px !important;
  max-width: 313px !important;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.card .card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: #252525 !important;

  height: 4rem;
}

.card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #252525 !important;
}

.autor-depoimento span {
  font-size: 20px;
  font-weight: 400;
  color: #fff;

  line-height: 24px;
}

.escritorio-depoimento span {
  font-size: 20px;
  font-weight: 400;
  color: #fff;

  line-height: 24px;
}

.card-perguntas{
  width: 642px;
  height: 313px;

  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #252525;
}

.card-perguntas .card-body {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5rem;
}

.card-perguntas .card-body .card-text { 
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;

  color: #535353;
}

.ellipse {
  top: 0;
  right: 0;

  position: absolute;
  z-index: -1;
}

.statistic .statistic-icon {
  margin-right: 1.5rem;
}

.statistic .statistic-text h5{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  margin-bottom: 0;
  color: #838383;
}
.statistic .statistic-text p{
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  
  margin-bottom: 0;
  color: #000000;
}

.list-perguntas li {
  background: #06777A;
  border-radius: 4px;
  width: auto;

  border: 1px solid #06777A;
  padding: 1rem 3.5rem;
  margin: .4rem 0;

  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  cursor: pointer;
  color: #E5E5E5;
  transition: all .2s ease-in-out;
  padding-right: 0rem;

}
@media(max-width: 991px) {
  .list-perguntas li {
    padding: 1rem 1.5rem;
  }
}

.list-perguntas li .tag-play {
  opacity: 0;
  
  transition: all 0s ease-in-out;
}



.list-perguntas .active .tag-play { 
  display: flex;
  opacity: 1;
}

.list-perguntas li:hover .tag-play { 
  display: flex;
  opacity: 1;
}



@media (max-width: 500px) {
  button {
    max-width: 100%;
  }
  .list-questions {
    width: 100% !important;
  }
  .conteiner-card { 
    width: 100% !important;
  }

  .data-contact p {
    font-weight: 400;
    font-size: 28px;
    color: #E5E5E5;
  }
  
  .data-contact p b {
    font-weight: 900;
    font-size: 28px;
    color: #FFF;
  }
}

@media (max-width: 1020px) {
  #whatWheDo div{
    max-width: 100%;
  }

  .desc-depoimento { 
    width: 100%;
    text-align: left;
  }
 .img-depoimento img {
  width: 100%;
 }

}

@media (min-width: 1021px) {
  
  .list-perguntas .active {
    margin-right: -55px;
    padding-right: 1rem;
  }
  
  .list-perguntas li:hover {
    margin-right: -55px;
    padding-right: 1rem;
  }
}
